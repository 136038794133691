import React, { useState, useEffect } from 'react'
import Marker from '../marker/marker'
import Footer from "../footer/footer"
import Header from "../header/header"
import Home from '../formRequestHome/formRequestHome'
import { Context } from '../context/provider'
import UploadVehicleInfoRequest from './upload'
import VehicleInfoSummary from './summary'
import { getStorageItem } from '../../utilities/getStorageItem'

export default function VehicleInfoRequest() {
  const [current, setCurrent] = useState(getStorageItem('mcn-form-filled') === 'true' ? 1 : '');

  useEffect(() => {
    if (typeof window != 'undefined') {
      window.scrollTo(0,0)
    }
  }, [current])

  useEffect(() => {
    if (typeof window != 'undefined' && window.location.search.includes('test=')) {
      const search = window.location.search
      const page = search.split('=')[1]
      setCurrent(parseInt(page))
    }
  }, [])

  const markerItems = [
    'Upload document',
    'Summary',
  ]

  const formName = 'Vehicle Information Request' 
  const description = 'Any and all vehicle information requests. There is no fee.'

  const requiredItems = <>
    <li>Filled vehicle information request form</li>
  </>

  useEffect(() => {
    if (current === 2) {
      window.location.href = '/thankyou'
    }
  }, [current])

  return (
    <Context.Provider value={{ current, setCurrent }}>
      <Header title={parseInt(current) >= 0 ? formName : ''} />
      {parseInt(current) >= 0 && current <= 2 && <Marker items={markerItems}/>}
      {current === '' && <Home form='/vehicle-info.pdf' label={formName} description={description} items={requiredItems} />}
      {current === 0 && <UploadVehicleInfoRequest />}
      {current === 1 && <VehicleInfoSummary />}
      <Footer />
    </Context.Provider>
  )
}
