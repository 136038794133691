import React, { useState, useContext } from 'react'
import DisplayItem from '../displayItem/displayItem'
import styles from '../uploadSummary/uploadSummary.module.scss'
import Buttons from '../buttons/buttons'
import { Context } from '../context/provider'
import Loader from 'react-loader-spinner'
import { usePostError } from '../../utilities/usePostError'
import axios from 'axios'
import { useSiteConfig } from '../layout/getSiteConfig'

export default function VehicleInfoSummary() {
  const context = useContext(Context)
  const storage = typeof window !== 'undefined' ? window.sessionStorage : {}
  const [loader, setLoader] = useState(false)

  const { vehicleInfoRequestApi } = useSiteConfig()
  const handleError = usePostError()

  const { name, vin, email, transactionId, formDocName, make, model } = storage;

  const handleNext = async () => {
    setLoader(true)
    window.sessionStorage.setItem('mcn-form-path', 'vehicle-info')

    const body = {
      key: process.env.GATSBY_SECRET,
      storage: {
        name,
        vin,
        email,
        transactionId,
        filenames: JSON.stringify([formDocName]),
        make,
        model,
      }
    }

    try {
      await axios.post(vehicleInfoRequestApi, { ...body })

      setLoader(false)
      context.setCurrent(2)

    } catch(e) {
      setLoader(false)
      handleError(e)
    }
  }
  
  const handleBack = () => {
    context.setCurrent(0)
  }

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <h2>Summary</h2>

        <DisplayItem {...{
          value: name,
          label: 'Name',
        }} />
        <DisplayItem {...{
          value: email,
          label: 'Email',
        }} />
        <DisplayItem {...{
          value: vin,
          label: 'VIN',
        }} />
        <DisplayItem {...{
          value: make,
          label: 'Make',
        }} />
        <DisplayItem {...{
          value: model,
          label: 'Model',
        }} />
        <DisplayItem {...{
          value: formDocName,
          label: 'Request form',
        }} />

        <Buttons {...{
          handleBack,
          handleNext,
          backLabel: 'BACK',
          nextLabel: 'CONFIRM'
        }}>
          {loader && <div className={styles.loader}>
            <Loader type="ThreeDots" color="#2BAF49" height={20} width={80} />
          </div>}
        </Buttons>
      </div>
    </section>
  )
}