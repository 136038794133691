import React, { useState } from 'react'
import styles from './fileUpload.module.scss'
import Filename from '../filename/filename'
import sanitize from 'sanitize-filename'
import PropTypes from 'prop-types'

export default function FileUpload(props) {
  const { label, name, fileName, setFileName, setAttachment, sessionKey } = props;
  const [showTypes, setShowTypes] = useState(false)

  const getAttachmentType = (attachment) => attachment.name.split('.').pop()

  function handleFileChange(e) {
    if (!e.target.files.length) {
      return
    }

    const file = e.target.files[0]
    if (!['jpg', 'jpeg', 'png', 'pdf'].includes(getAttachmentType(file)) || file.size > 5242880)
      setShowTypes(true)
    else {
      setShowTypes(false)
      setFileName(sanitize(file.name))
      setAttachment(file)
    }
  }

  function removeFile() {
    setAttachment(null)
    setFileName(null)
    window.sessionStorage.removeItem(sessionKey)
  }

  return (
    <div className={styles.fileUpload}>
      <label {...{
        className: styles.upload + ` ${(fileName && fileName !== "null") ? `${styles.hide}` : ""}`,
        htmlFor: name,
        style: { backgroundImage: 'url(/paper-clip.svg' },
      }}>
        {label}
      </label>
      <input type="file" id={name} name={name} className={styles.hide} onChange={handleFileChange} />

      <Filename {...{ filename: fileName, removeFile }} />
      <p className={styles.fileTypes + ` ${showTypes ? `${styles.show}` : ''}`}>
        Accepted file types: .jpg .png .pdf Max size: 5mb
      </p>
    </div>
  )
}

FileUpload.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  fileName: PropTypes.string,
  setFileName: PropTypes.func,
  setAttachment: PropTypes.func,
  sessionKey: PropTypes.string,
}