import React, { useState, useContext, useEffect } from 'react'
import styles from '../uploadDocuments/uploadDocuments.module.scss'
import Buttons from '../buttons/buttons'
import FileUpload from '../fileUpload/fileUpload'
import ErrorMessage from '../errorMessage/errorMessage'
import { useSiteConfig } from '../layout/getSiteConfig'
import { usePostError } from '../../utilities/usePostError'
import axios from 'axios'
import { Context } from '../context/provider'
import { getStorageItem } from '../../utilities/getStorageItem'
import Loader from 'react-loader-spinner'
import Input from '../input/input'
import { getTransactionId } from '../../utilities/getTransactionId'
import CheckInput from '../checkInput/checkInput'
import { validateEmail } from '../../utilities/validateEmail'

export default function UploadVehicleInfoRequest() {
  const [custName, setCustName] = useState(getStorageItem('name'))
  const [email, setEmail] = useState(getStorageItem('email'))
  const [vin, setVin] = useState(getStorageItem('vin'))
  const [validEmail, setValidEmail] = useState(false)
  const [confirmEmail, setConfirmEmail] = useState(getStorageItem('email'))
  const [make, setMake] = useState(getStorageItem('make'))
  const [model, setModel] = useState(getStorageItem('model'))
  const [formDoc, setFormDoc] = useState(null)
  const [formDocName, setFormDocName] = useState(getStorageItem('formDocName'))
  const [errorMessage, setErrorMessage] = useState('')
  const context = useContext(Context)
  const [loader, setLoader] = useState(false)
  const [submit, setSubmit] = useState(false)

  const { uploadApi } = useSiteConfig()
  const handleError = usePostError()

  const uploadAttachment = async (transactionId, file, fileKey, filename) => {
    const formData = new FormData()
    formData.set(fileKey, file, filename)
    formData.set('id', transactionId)
    const key = process.env.GATSBY_SECRET
    formData.set('key', key)
    await axios.post(uploadApi, formData)
  }

  const uploadAttachments = async (transactionId) => {
    if (!formDoc) {
      return
    }
    
    await uploadAttachment(transactionId, formDoc, 'document', formDocName);
  }

  const isMatch = email === confirmEmail

  const handleSubmit = async () => {
    setSubmit(true)
    const transactionId = getTransactionId()

    const isEmail = validateEmail(email, setValidEmail)

    const requiredFields = [isEmail, isMatch, vin, custName, make, model]

    if (!requiredFields.every(x => x)) {
      return
    }

    const requiredDocs = [
      { name: 'Vehicle Information Request Form', value: formDocName },
    ]

    if (!requiredDocs.every(x => x.value)) {
      setErrorMessage(`${requiredDocs.find(x => !x.value).name} is required`)
      return
    }

    setErrorMessage('')
    setLoader(true)

    window.sessionStorage.setItem('mcn-form-filled', true)

    try {
      await uploadAttachments(transactionId)
      
      const items = {
        formDocName,
        transactionId,
        email,
        name: custName,
        vin,
        make,
        model,
      }

      Object.entries(items).forEach(([key, value]) => {
        window.sessionStorage.setItem(key, value)
      })

      setSubmit(false)
      setLoader(false)
      context.setCurrent(1)

    } catch(e) {
      handleError(e)
      setLoader(false)
    }
  }

  const handleBack = () => {
    context.setCurrent('')
    setErrorMessage('')
  }

  const handleChange = (e) => {
    const value = e.target.value
    if (e.target.name === 'name') {
      setCustName(value)
    }
    if (e.target.name === 'email') {
      setEmail(value)
      if (submit) {
        validateEmail(value, setValidEmail)
      }
    }
    if (e.target.name === 'confirmEmail') {
      setConfirmEmail(value)
    }
    if (e.target.name === 'vin') {
      setVin(value)
    }
    if (e.target.name === 'make') {
      setMake(value)
    }
    if (e.target.name === 'model') {
      setModel(value)
    }
  }

  useEffect(() => {
    window.sessionStorage.setItem('mcn-form-filled', false)
  }, [])

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <h2>Upload document</h2>
        <div className={styles.inputs}>
          <Input {...{
            label: 'Name',
            name: 'name',
            value: custName,
            xStyle: 'left',
            handleChange,
            invalid: [submit, !custName],
          }} />
          
           <Input {...{
            label: 'VIN',
            name: 'vin',
            value: vin,
            xStyle: 'right',
            handleChange,
            invalid: [submit, !vin]
          }} />
          
        </div>
        <div className={styles.inputs}>
          <Input {...{
            label: 'Email',
            name: 'email',
            value: email,
            xStyle: 'left',
            handleChange,
            invalid: [submit, (!email || !validEmail)],
          }}>
            <CheckInput {...{
              text: 'Please enter a valid email address',
              values: [submit, (!email || !validEmail)],
            }} />
          </Input>
          <Input {...{
            label: 'Confirm email',
            name: 'confirmEmail',
            value: confirmEmail,
            xStyle: 'right',
            handleChange,
            invalid: [submit, (!isMatch || !confirmEmail)],
          }}>
            <CheckInput {...{
              text: 'The email address entered does not match',
              values: [submit, confirmEmail, !isMatch],
            }} />
          </Input>
        </div>
        <div className={styles.inputs}>
          <Input {...{
            label: 'Make',
            name: 'make',
            value: make,
            xStyle: 'left',
            handleChange,
            invalid: [submit, !make],
          }} />
           <Input {...{
            label: 'Model',
            name: 'model',
            value: model,
            xStyle: 'right',
            handleChange,
            invalid: [submit, !model]
          }} />
        </div>
        <div className={styles.uploadButtons}>
          <FileUpload {...{
            label: 'Vehicle Information Request Form',
            setFileName: setFormDocName,
            setAttachment: setFormDoc,
            fileName: formDocName,
            name: 'form-document'
          }} />
          <ErrorMessage message={errorMessage} />
        </div>
        <Buttons {...{
          handleBack,
          handleNext: handleSubmit,
          backLabel: 'BACK',
          nextLabel: 'NEXT'
        }}>
          {loader && <div className={styles.loader}>
            <Loader type="ThreeDots" color="#2BAF49" height={20} width={80} />
          </div>}
        </Buttons>
      </div>
    </section>
  )
}