import React from "react"
import VehicleInfoRequest from "../components/vehicleInfoRequest/vehicleInfoRequest";
import Layout from "../components/layout/layout"

export default function VehicleInfoRequestPage() {
  return (
    <Layout>
      <VehicleInfoRequest />
    </Layout>
  );
}
